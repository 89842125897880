import * as DOMPurify from 'dompurify';
import { useState, useEffect } from 'react';
import getBaseUrl from '../../base/htttp';

const Footer = () => {
    const [footer, setFooter] = useState("");
    const sanitizedContent = DOMPurify.sanitize(footer, {
        ADD_TAGS: ['iframe','embed'],
        ADD_ATTR: ['src','target','rel'],        
    });
    useEffect(() => {
        fetch(getBaseUrl()+"footer",{method:'POST'})        
            .then((response) => response.json())
            .then((result) => {
                setFooter(result.data.footer);
            });        
    }, []);
    return (                
        <>
            <div dangerouslySetInnerHTML={{__html: sanitizedContent}}></div>     
            <div className="back-top"><i className="feather-icon icon-chevron-up"></i></div>               
        </>
    );
};

export default Footer;